<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <div class="d-flex flex-column px-4 pt-4 pb-2">
            <h3 class="font-weight-bold mb-4" style='font-size: 30px'>Editar Categoría</h3>
          </div>
          <form class='px-4 pt-4' style='height: auto;' @submit.prevent="saveCategoria">
            <div class="row pt-4 align-items-center">
              <div class='col-12 col-md-6 pb-3 pb-md-0'>
                <label for="Nombre">Nombre *</label>
                <b-form-input id="Nombre" name='Nombre' required placeholder="Ingrese Nombre" trim
                  :value='saveCategoriaobjet.Categoria.Nombre'></b-form-input>
              </div>
            </div>
            <div class='pt-3'>
              <label for="Descripcion">Descripción</label><br />
              <b-form-textarea rows="6" class='p-3' id='Descripcion' name='Descripcion'
                placeholder="Ingrese Descripción" required
                :value='saveCategoriaobjet.Categoria.Descripcion'></b-form-textarea>
            </div>
            <div class='d-flex pt-4 justify-content-end'>
              <vs-button size="large" dark shadow
                @click.prevent="$router.push('/helex/Erp/Contabilidad/Categorias')">Cancelar</vs-button>
              <vs-button size="large" :loading="waiting">Guardar</vs-button>
            </div>
          </form>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import Swal from 'sweetalert2';
  import { core } from '../../../../../config/pluginInit';
  export default {
    data: () => ({
      waiting: false,
      saveCategoriaobjet: {
        Categoria: {
          Nombre: null,
          Descripcion: null,
        }
      },
      editarCategoriaobjet: {
        Categoria: {
          Nombre: null,
          Descripcion: null,
        }
      },
    }),
    async mounted() {
      core.index();
      await this.GetCategoria();
      window.$("#datatable_categoria").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
    },
    methods: {
      async GetCategoria() {
        let res = await this.$store.dispatch("hl_get", {
          path: 'CONTCategoria/GetCategoriaById/' + this.$route.params.id,
        });
        console.log(res);
        if (res.data.length > 0) {
          res.data.map(item => {
            this.saveCategoriaobjet.Categoria.Nombre = item.Nombre;
            this.saveCategoriaobjet.Categoria.Descripcion = item.Descripcion;
          })
        }
      },
      async saveCategoria(event) {
        const data = Object.fromEntries(new FormData(event.target).entries());
        this.editarCategoriaobjet.Categoria = data;
        try {
          this.waiting = true;
          const response = await this.$store.getters.fetchPost({
            path: `CONTCategoria/EditarCategoria/${this.$route.params.id}`,
            data: this.editarCategoriaobjet
          });
          if (response.ok) {
            Swal.fire({
              title: 'Editar Cuenta',
              text: 'Se ha editado la Cuenta',
              icon: 'success',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              this.$router.push('/helex/Erp/Contabilidad/Categorias');
            });
          } else {
            Swal.fire({
              title: 'Error',
              text: 'No se ha editado la Cuenta',
              icon: 'error',
              confirmButtonText: 'Aceptar'
            });
          }
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: 'No se ha editado la Cuenta',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        } finally {
          this.waiting = false;
        }
      }
    }
  }
</script>

<style>
  .vs-select__input {
    background-color: transparent !important;
    border: 1px solid var(--vs-colors--dark) !important;
    width: 100%;
  }

  .vs-select-content {
    max-width: 50%;
  }

  .vs-input {
    width: 100%;
    border-radius: 7px;
  }
</style>